import { Button } from "@e-seikatsu/design-system";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";

type IpRestrictionPageProps = {
  clientIp: string;
  onClickReturnToLogin: () => void;
};

const IpRestrictionPage = ({
  clientIp,
  onClickReturnToLogin,
}: IpRestrictionPageProps): JSX.Element => (
  <ErrorPanel
    heading="アクセスが制限されています"
    subHeading={
      <div>
        <div>
          所属法人の管理者による IP アドレス制限によりアクセスが制限されました。
        </div>
        <div>{`( ご利用中の IP アドレス: ${clientIp} )`}</div>
        <div>許可されている IP アドレスからアクセスいただくか、</div>
        <div>IP アドレス制限の緩和を管理者に依頼してください。</div>
      </div>
    }
    action={
      <Button variant="contained" onClick={(): void => onClickReturnToLogin()}>
        ログイン画面に戻る
      </Button>
    }
  />
);

export default IpRestrictionPage;
